<template>
  <div class="panel" v-click-outside="onClickOutside">
    <v-text-field
      v-model="ths_data"
      :label="label"
      :name="name"
      outlined
      dense
      background-color="white"
      @keyup="($event) => onFilterList($event.target.value)"
      @focus="onFocus"
      :error-messages="error_messages"
      autocomplete="new-password"
    />
    <div class="wrap-auto-complete" v-if="listFiltered.length && display">
      <div
        class="box-suggestion"
        v-for="(item, index) in listFiltered"
        :key="index"
        @click="onSelect(item)"
        v-html="
          makeBold(
            `${item.district} >> ${item.amphoe} >> ${item.province} >> ${item.zipcode}`
          )
        "
      ></div>
    </div>
  </div>
</template>

<script>
import raw_database from "@/assets/json/raw_database.json";

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: [String],
      default: null,
    },
    label: {
      type: [String],
      default: null,
    },
    key_name: {
      type: [String],
      default: null,
    },
    error_messages: {
      type: [String],
      default: null,
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      this.ths_data = newVal;
    },
  },
  mounted() {
    this.ths_data = this.value;
  },
  data() {
    return {
      loading: false,
      list: raw_database,
      listFiltered: [],
      ths_data: "",
      display: false,
    };
  },
  methods: {
    onClickOutside() {
      this.display = false;
    },
    async onFilterList(value) {
      this.$emit("onChange", this.ths_data);
      this.display = true;
      if (this.ths_data) {
        this.listFiltered = await this.list.filter((el) => {
          return el[`${this.key_name}`].toString().indexOf(value) != -1;
        });
      } else {
        this.listFiltered = [];
      }
    },
    onFocus() {
      this.display = true;
      // this.onFilterList(this.ths_data);
      if (this.ths_data) this.onFilterList(this.ths_data); else this.listFiltered = [];
      if (this.listFiltered.length == 0) this.display = false;
    },
    onSelect(data) {
      this.display = false;
      this.$emit("onChangeAddress", data);
    },
    makeBold(input) {
      var wordsToBold = [this.ths_data];
      var replace = new RegExp("(" + wordsToBold.join("|") + ")", "ig");
      let html = input.replace(replace, "<b>$1</b>");
      return html;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scope>
.panel {
  position: relative;
}
.wrap-auto-complete {
  position: absolute;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.42);
  top: 40px;
  z-index: 20;
}
.box-suggestion {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 10px 10px;
  cursor: pointer;
}
.box-suggestion:hover {
  background-color: #f5f5f5;
}
</style>

